import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';

@Options({})
export default class WithModel extends Vue {
    
    @Prop() modelValue: any;

    localValue: any = null;

    @Watch("modelValue", {deep : true})
    onModelValueChange(next, prev){
        if(next != this.localValue)
            this.localValue = JSON.parse(JSON.stringify(next));
    }

    @Watch("localValue", {deep : true})
    emitModel(val: any){
        if(this.modelValue != val){
            this.$emit("update:modelValue", val);
        }
    }
    
    created(){
        this.localValue = this.modelValue;
    }
}