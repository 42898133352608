import { h } from 'vue';
import { RouteRecordRaw, RouterView } from 'vue-router';

export default (): Array<RouteRecordRaw> => {
    return [
        {
            path: '/face-recognition-settings',
            name: 'face recognition settings',
            component: () => import("./views/faceRecognitionSettings.vue")
        }
        
        // {
        //     path: '/face-recognition-settings',
        //     component: () => h(RouterView),
        //     children: [
        //         {
        //             path: '/face-recognition-settings',
        //             name: 'face recognition settings',
        //             component: () => import("./views/faceRecognitionSettings.vue")
        //         }
        //     ]   
        // }
    ];
};