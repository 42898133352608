import { h } from 'vue';
import { RouteRecordRaw, RouterView } from 'vue-router';

export default (): Array<RouteRecordRaw> => {
    return [        
        {
            path: '/exhibitions',
            component: { render: () => h(RouterView) },
            children: [
                {
                    path: '/exhibitions',
                    name: 'exhibitions',
                    component: () => import("./views/exhibitions.vue")
                },
                {
                    path: '/editExhibitions/:identifier?',
                    name: 'editExhibition',
                    component: () => import("./views/editExhibition.vue")
                },
                {
                    path: '/exhibitions-detail/:exhibitionIdentifier?',
                    name: 'exhibition detail',
                    component: () => import("./views/exhibitionDetail.vue")
                }
            ]
        }
    ];
};