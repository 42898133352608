import { createApp } from 'vue';
import { LocalizationServices } from './services/LocalizationServices';
import App from './App.vue';
import router from './router';
import modalPlugin from './plugins/modalPlugin';
import store from './store';
import FTS from '@/filters';
import * as DIR from '@/directives';

import 'swiper/css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/css/custom_table.css';
import './assets/css/style.css';

import message from 'popular-message';
message.config({ duration: 4})
import 'popular-message/index.css';

const app = createApp(App)
    .use(store)
    .use(modalPlugin)
    .use(router)
    .directive('date', DIR.date)
    .directive("localizedPlaceholder", DIR.localizedPlaceholderDirective)
    .directive('language', DIR.language);

app.config.globalProperties.$filters = FTS;
app.config.globalProperties.$localizationService = LocalizationServices;
app.config.globalProperties.$message = message;

const ComponentContext = require.context('./components', true, /\.vue$/i);
ComponentContext.keys().forEach(fileName => {
    var component = ComponentContext(fileName);
    let componentName = fileName.split('/').pop().split('.')[0];
    app.component(componentName, (<any>component).default || component);
})

import OpTable from '@/components/opTable/opTable.vue';
app.component('OpTable', OpTable);

app.mount('#app')