import { StorageServices } from '@/services/StorageServices';
import { CommonServices } from './CommonServices';
import { LocalizationClient } from './Services';
import store from '@/store';
import * as OM from '@/model';

export class _LocalizationServices {

    setLanguage(language: string) {
        var loggedUser = StorageServices.getLoggedUser();
        if(loggedUser.identifier) {
            // AppUserClient.changeLanguageAndGetToken(language, false)
            // .then( x => {
            //     loggedUser.preferredCulture = language;
            //     StorageServices.setLoggedUser(loggedUser);
            //     store.state.loggedUser = loggedUser;
            //     CommonServices.setAuthToken(x);
            // }).catch( err => {
            //     loggedUser.preferredCulture = language;
            //     StorageServices.setLoggedUser(loggedUser);
            //     store.state.loggedUser = loggedUser;
            // })
        }
        else {
            StorageServices.setLoggedUser(loggedUser);
            store.state.loggedUser = loggedUser;

            StorageServices.SetLanguage(language);
            store.state.selectedLanguage = language;

            store.state.showSpinner++;
            location.reload();
        }
    }
    
    getLanguage() {
        return store.state.selectedLanguage;
    }
    
    constructor() {}

    checkLocalizedValue(key: string, value: string, numberAsterisk = 0): string {
        var exist = store.state.consts.localizedValues.find(x => {
            return x.key == key;
        });

        var asterisks = "";

        for(var i = 0; i < numberAsterisk; i++)
            asterisks += "*";

        if(store.state.consts.localizedValues.length == 0)
            return value + asterisks;
    
        if(exist) {
            var selectedCulture = LocalizationServices.getLanguage();
            return exist.values[selectedCulture] + asterisks;
        }
        else {
            var newValue = new OM.KeyValuePairOfStringAndString();
            newValue.key = key;
            newValue.value = value;
    
            LocalizationClient.saveLocalizedValue(newValue, false)
            .then(x => {
    
                var newLocalizedValue = new OM.LocalizedEntity();
                newLocalizedValue.key = key;
                newLocalizedValue.identifier = "";

                store.state.consts.enabledLanguages.forEach( lang => {
                    newLocalizedValue.values[lang] = value;
                })
    
                store.state.consts.localizedValues.push(newLocalizedValue);
            })
            
            return value + asterisks;
        }
    }

    getLocalizedValue(key: string, value: string, afterText: string = '', prevText: string = ''): string {
        var existing = store.state.consts.localizedValues.find(x => x.key == key);
        if(existing) {
            var selectedCulture = LocalizationServices.getLanguage();
            return prevText + existing.values[selectedCulture] + afterText;
        } else {
            var newValue = new OM.KeyValuePairOfStringAndString();
            newValue.key = key;
            newValue.value = value;
            LocalizationClient.saveLocalizedValue(newValue, false)
            .then(x => {
                var newLocalizedValue = new OM.LocalizedEntity();
                newLocalizedValue.key = key;
                newLocalizedValue.identifier = "";
                store.state.consts.enabledLanguages.forEach(x => {
                    newLocalizedValue.values[x] = value;
                })
                store.state.consts.localizedValues.push(newLocalizedValue);
            })
            return prevText + value + afterText;
        }
    }

    getTextFromValues(value: { [key: string]: string; }, customLang: string = "") {
        var lang = "en-US";
        if (customLang)
            lang = customLang;

        var keys = Object.keys(value);
        if (keys.indexOf(lang) == -1)
            return "";

        return value[lang];
    }
}
export let LocalizationServices = new _LocalizationServices();