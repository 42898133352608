import * as OM from '@/model';
import { LocalizationServices } from './services/LocalizationServices';

export function createBase64FromFile(file): Promise<string> {
    let prom = new Promise<string>((resolve, reject) => {
        if (!file) {
            resolve("");
            return;
        }

        if (!file.type.match('image.*')) {
            alert('Select an image');
            return;
        }

        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            resolve(<string>reader.result);
        };
    })

    return prom;
}

export function createPreviewFromImage(file) : Promise<string> {
    let prom = new Promise<string>((resolve, reject) => {
        if(!file){
            resolve("");
            return;
        }

        if (!file.type.match('image.*')) {
            alert('Select an image');
            return;
        }

        if(!window.cordova){
            var url = URL.createObjectURL(file);
            resolve(url);
            return;
        }
        
        var reader = new FileReader();

        reader.onload = (e:any) => {
            resolve(e.target.result);
        }
        reader.readAsDataURL(file);
    })

    return prom;
}

export function getPreviewVideoFromAttachment(file: File): Promise<File>{
    let prom = new Promise<File>((resolve, reject) => {
        if(!file)
            resolve(null);
        
        var index = file.name.lastIndexOf(".");
        const fileName = "preview_" + file.name.substring(0, file.name.length + 1 - index) + ".jpg";
        const canvas = document.createElement("canvas");
        const video = document.createElement("video");
    
        // this is important
        video.autoplay = true;
        video.muted = true;
        video.src = URL.createObjectURL(file);
    
        video.onloadeddata = () => {
            let ctx = canvas.getContext("2d");
            canvas.width = video.videoWidth;
            canvas.height = video.videoHeight;
    
            ctx.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
            video.pause();
            
            let filePreview: File = null;
            canvas.toBlob((blob) => {
                filePreview = new File([blob], fileName, { type: "image/jpeg" });
                return resolve(filePreview);
            }, 'image/jpeg');
            
        };
    })

    return prom;
}

export function getUniversalDate(input: any): Date {
    var data = new Date(input);
    data.setMinutes(data.getMinutes() + data.getTimezoneOffset());

    return data;
}

export function getStructurePath(sectionIdentifier: string, sections : OM.SectionItem[]) : string {

    var result = "";

    if(sections.length == 0)
        return result;

    var section = sections.find( x => x.identifier == sectionIdentifier);
    if(section != null)
        return LocalizationServices.getTextFromValues(section.name.values);

    section = sections.find( x => x.childrenIds.indexOf(sectionIdentifier) != -1);

    if(section == null)
        return result

    result = LocalizationServices.getTextFromValues(section.name.values);

    result += " - " + getStructurePath(sectionIdentifier, section.children);

    return result;
}