import { h } from 'vue';
import { RouteRecordRaw, RouterView } from 'vue-router';

export default (): Array<RouteRecordRaw> => {
    return [
        {
            path: '/comments',
            name: 'comments',
            component: () => import("./views/comments.vue")
        },
        
        // {
        //     path: '/comments',
        //     component: () => h(RouterView),
        //     children: [
        //         {
        //             path: '/comments',
        //             name: 'comments',
        //             component: () => import("./views/comments.vue")
        //         },
        //     ]
        // }
    ];
};