import { h, render } from 'vue';
import { RouteRecordRaw, RouterView } from 'vue-router';

export default (): Array<RouteRecordRaw> => {
    return [
        {
            path: '/users',
            component: { render: () => h(RouterView) },
            children: [
                {
                    path: '/users',
                    name: 'users',
                    component: () => import("./views/users.vue")
                },
                {
                    path: '/editUser/:identifier?',
                    name: 'editUser',
                    component: () => import("./views/editUser.vue")
                },
            ]
        }
    ];
};