import { h } from 'vue';
import { RouteRecordRaw, RouterView } from 'vue-router';

export default (): Array<RouteRecordRaw> => {
    return [       
        {
            path: '/authors',
            component: { render: () => h(RouterView) },
            children: [
                {
                    path: '/authors',
                    name: 'authors',
                    component: () => import("./views/authors.vue")
                },
                {
                    path: '/editAuthor/:identifier?',
                    name: 'editAuthor',
                    component: () => import("./views/editAuthor.vue")
                }
            ]
        }
    ];
};