import moment from 'moment';
import { LocalizationServices } from './services/LocalizationServices';
import { StorageServices } from './services/StorageServices';
import { getUniversalDate } from './utils';
import store from './store';
import * as OM from "@/model";

export default {

    date(value: any, _format?: string, _showTime?: boolean) {
        if (value) {
            value = getUniversalDate(value);
            let format;
            if(!_format){
                format = 'YYYY MMMM DD';
                if(_showTime)
                    format += 'HH:mm:ss';
            } else {
                format = _format;
            }

            var preferredCulture = LocalizationServices.getLanguage();
            return moment(value).locale(preferredCulture).format(format);
        }
    },

    time(value: any, _format?: string, _showTime?: boolean) {
        if (value) {
            let format;
            if(!_format){
                format = 'HH:mm';
            } else {
                format = _format;
            }

            var preferredCulture = LocalizationServices.getLanguage();
            return moment(value).locale(preferredCulture).format(format);
        }
    },

    currency(value: any, decimals: number) {
        if(!decimals && decimals != 0)
            decimals = 2;
        if (value || value == 0) {
            let val = (value/1).toFixed(decimals).replace('.', ',')
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
        }
    },

    age(value: any) {
        if(!value)
            return "";
        
        var date = getUniversalDate(value);
        var today = new Date();

        var age = today.getFullYear() - date.getFullYear();
        var m = today.getMonth() - date.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < date.getDate())) {
            age--;
        }

        return age;
    },

    kmFromMeters(value: number) {
        var km = value / 1000;
        var format = Intl.NumberFormat("it-IT");
        var kmText = LocalizationServices.getLocalizedValue("app_Km", "Km");
        var result = format.format(km) + " " + kmText;

        return result;
    }
}