import { createStore } from 'vuex'
import { StorageServices } from '@/services/StorageServices';
import * as OM from '@/model';
import { AppRole } from '@/viewModel';

let store = {
  state: {
    selectedLanguage: "",
    showSpinner: 0,
    loggedUser: new OM.BaseApiLoginResponse(),
    consts: {
      localizedValues: <OM.LocalizedEntity[]>[],
      enabledLanguages: <string[]>[],
    },
    beforeNavCbs: [],
    afterNavCbs: []
  },
  getters: {
    isStaff(state){
      return state.loggedUser.role == AppRole.Staff
    },
    isAdmin(state){
      return state.loggedUser.role == AppRole.Admin
    }
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
}

export default createStore(store);