import { CommonServices } from './CommonServices';
import * as OM from '@/model';

class _StorageServices {
    
    private readFromLocalStorage<T>(lsName: string): T | null {
        let data = window.localStorage.getItem(lsName);
        if(data){
            return JSON.parse(data);
        } else {
            return null;
        }
    }
    
    getLoggedUser() {
        var ris = this.readFromLocalStorage<OM.BaseApiLoginResponse>('loggedUser');

        if (!ris) {
            var model = new OM.BaseApiLoginResponse();
            this.setLoggedUser(model);

            ris = model;
        }

        return ris;
    }
    
    setLoggedUser(response: OM.BaseApiLoginResponse) {
        window.localStorage.setItem('loggedUser', JSON.stringify(response));
    }

    setLoginToken(token: string) {
        return CommonServices.setAuthToken(token);
    }
    getLoginToken(): string {
        return window.localStorage.getItem('authtoken');
    }

    SetLanguage(language: string) {
        window.localStorage.setItem('locale', language);
    }

    GetLanguage() {
        let data = window.localStorage.getItem('locale');
        if(!data)
            return '';
        else
            return data;
    }

}

export let StorageServices = new _StorageServices();