import { createRouter, createWebHashHistory, createWebHistory, RouteRecordRaw } from 'vue-router'
import { loginModule } from "@/views/login";
import { dashboardModule } from '@/views/dashboard';
import { artsModule } from '@/views/arts';
import { exhibitionsModule } from '@/views/exhibitions';
import { exhibitionLocationModule } from '@/views/exhibitionLocation';
import { usersModule } from '@/views/users';
import { emotionsModule } from '@/views/emotionsComments';
import { commentsModule } from '@/views/comments';
import { authorsModule } from '@/views/authors';
import { faceRecognitionModule } from '@/views/faceRecognition';
import { dictionaryModule } from '@/views/dictionary';
import { myExhibitionLocationModule } from '@/views/myExhibitionLocation';
import store from '@/store';

const routes: Array<RouteRecordRaw> = [
  
  ...loginModule.routes(),

  {
      path: '/face-recognition/:exhibitionIdentifier/:numberArts/:timeout',
      name: 'face recognition',
      component: () => import("../views/faceRecognition/views/faceRecognition.vue")
  },
  // {
  //   path: '/face-recognition',
  //   name: 'face recognition',
  //   component: () => import("../views/faceRecognition/views/faceRecognition.vue")
  // },

  {
    path: "/",
    name: "layout",
    component: () => import("../views/Layout.vue"),
    children: [

      ...dashboardModule.routes(),
      ...authorsModule.routes(),
      ...artsModule.routes(),
      ...exhibitionsModule.routes(),
      ...exhibitionLocationModule.routes(),
      ...faceRecognitionModule.routes(),
      ...usersModule.routes(),
      ...emotionsModule.routes(),
      ...commentsModule.routes(),     
      ...dictionaryModule.routes(), 
      ...myExhibitionLocationModule.routes()
    ]
  },

]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
  
})

router.beforeEach((to, from, next) => {
    if(store.state.beforeNavCbs.length == 0)
        next();
    store.state.beforeNavCbs.forEach(x => {
        x(to, from, next);
    });
    store.state.beforeNavCbs = [];
})

export default router
