import { CommonServices } from '@/services/CommonServices';
import { StorageServices } from '@/services/StorageServices';
import { LocalizationServices } from './LocalizationServices';
import router from '@/router';
import store from '@/store';
import * as OM from '@/model';
import { getStartRoute } from '@/appRoutes';

class _LoginServices {
    
    CheckLogin(){
        var loggedUser = StorageServices.getLoggedUser();
		if(loggedUser && loggedUser.token){
            this.LoginCallback(loggedUser)
        } else
            router.replace('/welcome');
    }

    LoginCallback(loginResponse: OM.BaseApiLoginResponse) {

        StorageServices.setLoginToken(loginResponse.token);

        StorageServices.setLoggedUser(loginResponse);
        store.state.loggedUser = loginResponse;

        StorageServices.SetLanguage("en-US");
        store.state.selectedLanguage = "en-US";
        
        var startRoute = getStartRoute();
        router.push(startRoute);
        
        // AuthClient.tokenUpgrade(token)
        // .then( x => {
        //     StorageServices.setLoggedUser(x);
        //     store.state.loggedUser = x;
        //     StorageServices.SetLanguage(x.preferredCulture);
        //     store.state.selectedLanguage = x.preferredCulture;
            
        //     if(x.role == "Admin"){
        //         router.push("/dashboard");
        //     }else{
        //         router.push("/emotions")
        //     }
        // })
        // .catch(err => {
        //     LoginServices.logout();
        //     router.replace('/');
        // });
    };

    logout() {
        let prom = new Promise<void>((resolve, reject) => {
            CommonServices.destroyToken();
            this.resetLoggedUser();
            router.push("/")
            resolve();
        })
        return prom;
    }

    private resetLoggedUser() {
        var loggedUser = new OM.BaseApiLoginResponse();
        StorageServices.setLoggedUser(loggedUser);
        store.state.loggedUser = loggedUser;
    }

}

export let LoginServices = new _LoginServices();