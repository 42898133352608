import { h } from 'vue';
import { RouteRecordRaw, RouterView } from 'vue-router';

export default (): Array<RouteRecordRaw> => {
    return [        
        {
            path: '/exhibitionLocations',
            component: { render: () => h(RouterView) },
            children: [
                {
                    path: '/exhibitionLocations',
                    name: 'exhibition locations',
                    component: () => import("./views/exhibitionLocations.vue")
                },
                {
                    path: '/editExhibitionLocation/:identifier?',
                    name: 'editExhibitionLocation',
                    component: () => import("./views/editExhibitionLocation.vue")
                }
            ]
        }
    ];
};