import * as VM from '@/viewModel';
import store from './store';

export function getRoutes() {
    const userRole = store.state.loggedUser.role;

    return getAll().filter(x => {
        if (x.roles.indexOf(userRole) != -1)
            return x;
    })
}

export function getStartRoute() {
    const userRole = store.state.loggedUser.role;

    if(userRole == VM.AppRole.Admin)
        return dashboard.route;
        
    return dashboard.route;
}

function getAll() {
    return [
        dashboard,
        users,
        authors,
        arts,
        exhibitions,
        exhibitionLocations,
        myExhibitionLocation,
        translations,
        faceRecognitionSettings,
        emotions,
        comments,
    ]
}

const dashboard: VM.AppRoute = {
    key: "dashboard",
    text: "dashboard",
    route: "/dashboard",
    imgSrc: "bar-chart-64.png",
    roles: [
        VM.AppRole.Admin, VM.AppRole.Staff
    ]
}

const authors: VM.AppRoute = {
    key: "authors",
    text: "authors",
    route: "/authors",
    imgSrc: "brush-4-64.png",
    roles: [
        VM.AppRole.Admin, VM.AppRole.Staff
    ]
}

const arts: VM.AppRoute = {
    key: "arts",
    text: "arts",
    route: "/arts",
    imgSrc: "picture-64.png",
    roles: [
        VM.AppRole.Admin, VM.AppRole.Staff
    ]
}

const exhibitions: VM.AppRoute = {
    key: "exhibitions",
    text: "exhibitions",
    route: "/exhibitions",
    imgSrc: "option_grid.png",
    roles: [
        VM.AppRole.Admin, VM.AppRole.Staff
    ]
}

const exhibitionLocations: VM.AppRoute = {
    key: "locations",
    text: "locations",
    route: "/exhibitionLocations",
    imgSrc: "museum-64.png",
    roles: [
        VM.AppRole.Admin
    ]
}

const myExhibitionLocation: VM.AppRoute = {
    key: "Location",
    text: "Location",
    route: "/mylocation",
    imgSrc: "museum-64.png",
    roles: [
        VM.AppRole.Staff
    ]
}

const translations: VM.AppRoute = {
    key: "dictionary",
    text: "dictionary",
    route: "/translations",
    imgSrc: "comments-64.png",
    roles: [
        VM.AppRole.Admin
    ]
}

const faceRecognitionSettings: VM.AppRoute = {
    key: "faceRecognition",
    text: "face recognition",
    route: "/face-recognition-settings",
    imgSrc: "theatre-masks-64.png",
    roles: [
        VM.AppRole.Admin
    ]
}

const users: VM.AppRoute = {
    key: "users",
    text: "users",
    route: "/users",
    imgSrc: "user-5-64.png",
    roles: [
        VM.AppRole.Admin
    ]
}

const emotions: VM.AppRoute = {
    key: "emotions",
    text: "emotions",
    route: "/emotions",
    imgSrc: "theatre-masks-64.png",
    roles: [
    ]
}

const comments: VM.AppRoute = {
    key: "comments",
    text: "comments",
    route: "/comments",
    imgSrc: "comments-64.png",
    roles: [
    ]
}