import { RouteRecordRaw } from 'vue-router';

export default (): Array<RouteRecordRaw> => {
    return [
        {
            path: '/',
            name: 'login staff',
            component: () => import("./views/login_staff.vue")
        },
        {
            path: '/admin',
            name: 'login admin',
            component: () => import("./views/login_admin.vue")
        }
    ];
};