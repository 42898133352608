import * as OM from '@/model';

export class SelectOption {
    key: string;
    textKey: string;
    textValue: string;

    constructor() {
        this.key = "";
        this.textKey = "";
        this.textValue = "";
    }
}

export class LocalImage {
    previewFile: File;

    constructor() {
        this.previewFile = null;
    }
}

export class LocalVideo extends LocalImage {
    videoFile: File;

    constructor() {
        super();
        this.videoFile = null;
    }
}

export class AppRole {
    public static readonly Admin = "admin";

    public static readonly Staff = "staff";
    
    public static readonly Customer = "customer";
    public static readonly Anonymous = "anonymous";

    public static readonly BaseUser = "baseUser";
}

export class AppRoute {
    key: string;
    text: string;
    route: string;
    imgSrc: string;
    roles: string[];

    constructor() {
        this.key = "";
        this.text = "";
        this.route = "";
        this.imgSrc = "";
        this.roles = [];
    }
}

export class CloudParentName {
    public static readonly ExhibitionLocation = "ExhibitionLocation";
    public static readonly ExhibitionLocationSection = "ExhibitionLocationSection";
    public static readonly Exhibition = "Exhibition";
    public static readonly Author = "Author";
    public static readonly ExhibitionItem = "ExhibitionItem";
}

export class LocationSectionType {
    public static readonly SectionWithMap = "SectionWithMap";
    public static readonly SectionWithImage = "SectionWithImage";
}