import { h } from 'vue';
import { RouteRecordRaw, RouterView } from 'vue-router';

export default (): Array<RouteRecordRaw> => {
    return [       
        {
            path: '/arts',
            component: { render: () => h(RouterView) },
            children: [
                {
                    path: '/arts',
                    name: 'arts',
                    component: () => import("./views/arts.vue")
                },
                {
                    path: '/editArts/:identifier?',
                    name: 'editArts',
                    component: () => import("./views/editArts.vue")
                },
                {
                    path: '/patronage-list/:itemIdentifier',
                    name: 'patronage list',
                    component: () => import("./views/patronageList.vue")
                }
            ]
        }
    ];
};